<template>
  <div class="row justify-center items-start bg-grey-3" style="min-height: calc(100vh - 83px);">
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="mapel"
            v-model="selMapel"
            outlined
            label="Mata Pelajaran"
            style="min-width: 250px;"
            @input="getKDList"
          ></q-select>
          <q-btn
            :disable="selMapel == null"
            icon="add"
            color="primary"
            label="Tambah KD"
            @click="showDialogEdit(-1)"
          ></q-btn>
        </div>
        <q-input outlined label="Cari KD" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table flat bordered class="stickyTable">
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No KD</th>
            <th>Nama KD</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-indigo-1">
          <tr v-if="selMapel == null">
            <td class="text-center" colspan="4">Silahkan Pilih Mata Pelajaran Terlebih Dahulu</td>
          </tr>
          <tr v-else-if="KD.length == 0">
            <td class="text-center" colspan="4">Belum ada data pengajar</td>
          </tr>
          <tr v-for="(val, i) in filKD" :key="i">
            <td>{{ val.nomor }}</td>
            <td>{{ val.nama }}</td>
            <td class="q-gutter-sm">
              <q-btn flat dense icon="edit" color="secondary" @click="showDialogEdit(i)"></q-btn>
              <q-btn
                flat
                dense
                @click="
                  (toDel = {
                    id: val.id,
                    nama: val.nama,
                  }),
                    (confirm = true)
                "
                color="red"
                icon="delete_forever"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
    <q-dialog v-model="confirm" persistent>
      <q-card class="bg-negative text-white">
        <q-card-section class="row items-center">
          <strong class="q-ml-sm">
            Apa anda yakin ingin menghapus kompetensi dasar "{{
            toDel.nama
            }}"
            ?
          </strong>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="white" v-close-popup />
          <q-btn flat label="Ya" color="white" @click="deleteData(toDel.id)" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog persistent v-model="tambah" v-if="selMapel">
      <q-card style="width:300px">
        <q-card-section class="q-gutter-md">
          <q-input outlined disable v-model="selMapel.label" label="Nama Mapel"></q-input>
          <q-input outlined type="number" v-model="bundleKD.nomor" label="No KD"></q-input>
          <q-input outlined v-model="bundleKD.nama" label="Nama KD"></q-input>
        </q-card-section>
        <q-card-actions class="row justify-end">
          <q-btn outline label="Cancel" color="primary" v-close-popup></q-btn>
          <q-btn
            v-if="button=='tambah'"
            label="Tambah"
            color="primary"
            v-close-popup
            @click="tambahKD"
          ></q-btn>
          <q-btn v-else label="Edit" color="primary" v-close-popup @click="editKD"></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      button: "",
      bundleKD: {
        nama: "",
        nomor: 1
      },
      tambah: false,
      toDel: {
        id: 0,
        guru: "",
        rombel: "",
        mapel: ""
      },
      confirm: false,
      searchTerm: "",
      selMapel: null,
      mapel: [],
      ajar: [],
      KD: []
    };
  },
  computed: {
    filKD() {
      if (this.searchTerm == "") return this.KD;
      else {
        return this.KD.filter(val => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    }
  },
  mounted() {
    this.getListMapel();
  },
  methods: {
    getKDList() {
      this.$http
        .get(`/penilaian/kompetensi/getlistkd/${this.selMapel.value}`)
        .then(resp => {
          this.KD = resp.data;
        });
    },
    tambahKD() {
      this.$http
        .post(
          `/penilaian/kompetensi/tambah/${this.selMapel.value}`,
          this.bundleKD
        )
        .then(resp => {
          this.$q.notify({
            message: "Kompetensi Dasar berhasil ditambahkan",
            color: "positive"
          });
          this.getKDList();
        });
    },
    editKD() {
      this.$http.put("/penilaian/kompetensi/edit", this.bundleKD).then(resp => {
        this.$q.notify({
          message: "Kompetensi Dasar berhasil diupdate",
          color: "positive"
        });
        this.getKDList();
      });
    },
    deleteData(id) {
      this.$http.delete("/penilaian/kompetensi/hapus/" + id).then(resp => {
        this.getKDList();
      });
    },

    getListMapel() {
      this.$http
        .get(
          "/pengaturan/gurumapel/getlistmapel/" +
            localStorage.getItem("jenjang")
        ) //harusnya ditambah id kelas dari wali kelas
        .then(resp => {
          this.mapel = resp.data;
          // this.selMapel = this.mapel[0];
        });
    },
    showDialogEdit(idx) {
      this.bundleKD = {
        nomor: 1,
        nama: ""
      };
      this.tambah = true;
      if (idx < 0) {
        this.button = "tambah";
      } else {
        this.button = "edit";
        this.bundleKD = JSON.parse(JSON.stringify(this.KD[idx]));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
